<template>
  <Card class="pt-10 px-5 pb-14 mb-5 mb-lg-10" :class="{ 'px-10': !isMobile }">
    <header class="d-flex align-center">
      <VIcon v-if="strapi.Icon" :icon="strapi.Icon" class="mr-3" size="default" />
      <h3 class="paragraph-large -no-margin">{{ strapi.Heading }}</h3>
    </header>
    <VDivider class="mb-10 mt-4" />
    <div class="grid">
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerInvoiceAddressAddress">{{ strapi.AddressLabel }}</label>
        <TextField id="customerInvoiceAddressAddress" v-model="customerInvoiceAddress.address" name="customerInvoiceAddress.address" :placeholder="strapi.AddressPlaceholder" />
      </div>
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerInvoiceAddressPostalCode">{{ strapi.PostalCodeLabel }}</label>
        <TextField id="customerInvoiceAddressPostalCode" v-model="customerInvoiceAddress.zip" name="customerInvoiceAddress.zip" :placeholder="strapi.PostalCodePlaceholder" />
      </div>
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerInvoiceAddressCountry">{{ strapi.CountryLabel }}</label>
        <SelectCountry id="customerInvoiceAddressCountry" v-model="customerInvoiceAddress.country" :placeholder="strapi.CountryPlaceholder" name="customerInvoiceAddress.country_id" returnObject :clearable="false" />
      </div>
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerInvoiceAddressAddress2">{{ strapi.Address2Label }}</label>
        <TextField id="customerInvoiceAddressAddress2" v-model="customerInvoiceAddress.address2" name="customerInvoiceAddress.address2" :placeholder="strapi.Address2Placeholder" />
      </div>
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerInvoiceAddressCity">{{ strapi.CityLabel }}</label>
        <TextField id="customerInvoiceAddressCity" v-model="customerInvoiceAddress.city" name="customerInvoiceAddress.city" :placeholder="strapi.CityPlaceholder" />
      </div>
      <div v-if="customerInvoiceAddress.country?.hasStates" class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerInvoiceAddressState">{{ strapi.StateLabel }}</label>
        <SelectState id="customerInvoiceAddressState" :key="customerInvoiceAddress.country_id" v-model="customerInvoiceAddress.state" :placeholder="strapi.StatePlaceholder" name="customerInvoiceAddress.state_id" returnObject :queryBuilder="qb => qb.where('freight_country_id', customerInvoiceAddress.country_id).limit(100)" @onBeforeUnmount="onBeforeUnmount" />
      </div>
      <div v-if="customerInvoiceAddress.country?.hasBusinessTypes" class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerInvoiceAddressBusinessType">{{ strapi.BusinessTypeLabel }}</label>
        <SelectApi id="customerInvoiceAddressBusinessType" v-model="business_type_id" endpoint="business_types" :placeholder="strapi.BusinessTypePlaceholder" name="business_type_id" :queryBuilder="qb => qb.where('freight_country_id', customerInvoiceAddress.country_id).limit(100)" />
      </div>
    </div>
  </Card>
</template>

<script>
export default {
  name: 'InvoiceAddress',
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      customerInvoiceAddress: { ...this.modelValue },
      business_type_id: null
    };
  },
  watch: {
    'customerInvoiceAddress.country': {
      handler(country) {
        this.customerInvoiceAddress.country_id = country?.id;
      },
      immediate: true
    },
    'customerInvoiceAddress.state': {
      handler(state) {
        this.customerInvoiceAddress.state_id = state?.id;
      },
      immediate: true
    },
    customerInvoiceAddress: {
      handler() {
        this.$emit('update:modelValue', { ...this.modelValue, ...this.customerInvoiceAddress });
      },
      immediate: true,
      deep: true
    },
    business_type_id(id) {
      this.$emit('onBusinessTypeSelect', id);
    }
  },
  methods: {
    onBeforeUnmount() {
      this.customerInvoiceAddress.state = null;
    }
  }
};
</script>

<style lang="scss" scoped></style>
