import * as Utils from '@Utils/default';
import ExpiredStorage from 'expired-storage';
import mitt from 'mitt';
import Cookies from 'js-cookie';
import sprintf from 'sprintf-js';
import { query } from '@/api';
import { v4 as uuidv4 } from 'uuid';
import gsap from 'gsap';
import { clearAllBodyScrollLocks } from 'body-scroll-lock-upgrade';

export default {
  install: (Vue, options) => {
    const { application, ...controllerData } = window.Techship;
    const dom = { application: application || {}, controllerData: controllerData || $('#controller-data').data() || {} };
    const props = {
      $dom: dom,
      $controller: {
        name: dom.application.controller || null,
        action: dom.application.action || null,
        data: dom.controllerData || {}
      },
      $isLoggedIn: dom.application.isLoggedIn || false,
      $isNotLoggedIn: !dom.application.isLoggedIn || false,
      $isInCheckout: dom.application.isInCheckout,
      $isNotInCheckout: !dom.application.isInCheckout,
      $csrfToken: dom.application.csrfToken || null,
      $routeParams: dom.application.routeParams,
      $host: dom.application.host,
      $utils: Utils,
      $mitt: mitt(),
      $gsap: gsap,
      $query: query,
      $strapi: dom.controllerData.strapi || {},
      $localStorage: new ExpiredStorage(),
      $sessionStorage: new ExpiredStorage(sessionStorage),
      $redirect: (location = '', options = {}) => {
        options = { newTab: false, ...options };
        if (options.newTab) {
          window.open(location, '_blank');
        } else {
          window.location.href = location;
        }
      },
      $reload: () => location.reload(),
      $currency: dom.application.currency,
      $user: dom.application.user,
      $country: dom.application.country,
      $state: dom.application.state,
      $logos: Utils.reduce(dom.application.logos, (acc, logo, index) => ((acc[index] = logo.Image), acc), {}),
      $breadcrumbs: dom.application.breadcrumbs || [],
      $sprintf: sprintf.sprintf,
      $uuid: uuidv4,
      $clearAllBodyScrollLocks: clearAllBodyScrollLocks,
      $cookies: {
        get: key => Cookies.get(key),
        set: (key, value, options) => Cookies.set(key, value, options),
        remove: (key, options) => Cookies.remove(key, options)
      }
    };

    Utils.each(props, (val, prop) => (Vue.config.globalProperties[prop] = val));
    Utils.each(Utils, (val, prop) => (Vue.config.globalProperties[`$${prop}`] = val));

    Vue.directive('clickOutside', {
      beforeMount: (el, binding) => {
        el.clickOutsideEvent = event => {
          // here I check that click was outside the el and his children
          if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            binding.value();
          }
        };
        document.addEventListener('click', el.clickOutsideEvent);
      },
      unmounted: el => {
        document.removeEventListener('click', el.clickOutsideEvent);
      }
    });

    Vue.directive('userTyping', {
      mounted: (el, binding, vnode, prevNode) => {
        el.addEventListener('input', event => {
          if (event.isTrusted) {
            binding.value(event);
          }
        });
      }
    });
  }
};
