<template>
  <Card class="pt-10 px-5 pb-14 mb-5 mb-lg-10" :class="{ 'px-10': !isMobile }">
    <header class="d-flex align-center">
      <VIcon v-if="strapi.Icon" :icon="strapi.Icon" class="mr-3" size="default" />
      <h3 class="paragraph-large -no-margin">{{ strapi.Heading }}</h3>
    </header>
    <VDivider class="mb-10 mt-4" />
    <Checkbox v-model="copyDetailsFromInvoiceAddressChecked" label="Copy details from Invoice Address" />
    <div class="grid mt-6">
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerDeliveryAddressAddress">{{ strapi.AddressLabel }}</label>
        <TextField id="customerDeliveryAddressAddress" v-model="customerDeliveryAddress.address" name="customerDeliveryAddress.address" :placeholder="strapi.AddressPlaceholder" :disabled="copyDetailsFromInvoiceAddressChecked" />
      </div>
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerDeliveryAddressPostalCode">{{ strapi.PostalCodeLabel }}</label>
        <TextField id="customerDeliveryAddressPostalCode" v-model="customerDeliveryAddress.zip" name="customerDeliveryAddress.zip" :placeholder="strapi.PostalCodePlaceholder" :disabled="copyDetailsFromInvoiceAddressChecked" />
      </div>
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerDeliveryAddressCountry">{{ strapi.CountryLabel }}</label>
        <SelectCountry id="customerDeliveryAddressCountry" v-model="customerDeliveryAddress.country" name="customerDeliveryAddress.country_id" :placeholder="strapi.CountryPlaceholder" returnObject :disabled="copyDetailsFromInvoiceAddressChecked" :clearable="false" />
      </div>
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerDeliveryAddressAddress2">{{ strapi.Address2Label }}</label>
        <TextField id="customerDeliveryAddressAddress2" v-model="customerDeliveryAddress.address2" name="customerDeliveryAddress.address2" :placeholder="strapi.Address2Placeholder" :disabled="copyDetailsFromInvoiceAddressChecked" />
      </div>
      <div class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerDeliveryAddressCity">{{ strapi.CityLabel }}</label>
        <TextField id="customerDeliveryAddressCity" v-model="customerDeliveryAddress.city" name="customerDeliveryAddress.city" :placeholder="strapi.CityPlaceholder" :disabled="copyDetailsFromInvoiceAddressChecked" />
      </div>
      <div v-if="customerDeliveryAddress.country?.hasStates" class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerDeliveryAddressState">{{ strapi.StateLabel }}</label>
        <SelectState id="customerDeliveryAddressState" :key="customerDeliveryAddress.country_id" v-model="customerDeliveryAddress.state" :placeholder="strapi.StatePlaceholder" name="customerDeliveryAddress.state_id" returnObject :queryBuilder="qb => qb.where('freight_country_id', customerDeliveryAddress.country_id).limit(100)" :disabled="copyDetailsFromInvoiceAddressChecked" @onBeforeUnmount="onBeforeUnmount" />
      </div>
      <div v-if="customerDeliveryAddress.country?.code === 'SE'" class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerDeliveryAddressWarehouseKeeper">{{ strapi.WarehouseKeeperLabel }}</label>
        <RadioGroup id="customerDeliveryAddressWarehouseKeeper" v-model="_customer.is_warehouse_approved" name="customer.is_warehouse_approved">
          <Radio label="Yes" :value="1" />
          <Radio label="No" :value="2" />
        </RadioGroup>
      </div>
      <div v-if="customerDeliveryAddress.country?.requiresVat" class="grid-item -col-span-12 -col-span-lg-4">
        <label class="mr-5 paragraph" for="customerDeliveryAddressVat">{{ strapi.VatLabel }}</label>
        <TextField id="customerDeliveryAddressVat" :key="customerDeliveryAddress.country_id" v-model="customerDeliveryAddress.vat" :placeholder="strapi.VatPlaceholder" name="customerDeliveryAddress.vat" :hint="strapi.VatInfoText" />
      </div>
    </div>
  </Card>
</template>

<script>
export default {
  name: 'DeliveryAddress',
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    customerInvoiceAddress: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      customerDeliveryAddress: { ...this.modelValue },
      copyDetailsFromInvoiceAddressChecked: false
    };
  },
  computed: {
    _customer: {
      get() {
        return this.customer;
      },
      set(customer) {
        this.$emit('update:customer', customer);
      }
    }
  },
  watch: {
    'customerDeliveryAddress.country': {
      handler(country) {
        this.customerDeliveryAddress.country_id = country?.id;
      },
      immediate: true
    },
    'customerDeliveryAddress.state': {
      handler(state) {
        this.customerDeliveryAddress.state_id = state?.id;
      },
      immediate: true
    },
    copyDetailsFromInvoiceAddressChecked(checked) {
      if (checked) {
        this.copyCustomerInvoiceAddressDetails(this.customerInvoiceAddress);
      }
    },
    customerInvoiceAddress: {
      handler(customerInvoiceAddress) {
        if (this.copyDetailsFromInvoiceAddressChecked) {
          this.copyCustomerInvoiceAddressDetails(customerInvoiceAddress);
        }
      },
      deep: true
    },
    customerDeliveryAddress: {
      handler() {
        this.$emit('update:modelValue', this.customerDeliveryAddress);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    copyCustomerInvoiceAddressDetails(customerInvoiceAddress) {
      this.customerDeliveryAddress = Object.assign({}, this.customerDeliveryAddress, customerInvoiceAddress);
    },
    onBeforeUnmount() {
      this.customerDeliveryAddress.state = null;
    }
  }
};
</script>

<style lang="scss" scoped></style>
