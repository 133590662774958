<template>
  <div class="pb-10">
    <div class="grid card-container">
      <div v-for="(employee, index) in employeesList" :key="index" class="mb-5 grid-item -col-span-12 -col-span-sm-6 -col-span-md-4 -col-span-lg-3">
        <VCard class="box-link-card" :class="{ hovered: hover === index || selected === index }" @mouseenter="hover = index" @mouseleave="hover = null" @click="toggleSelected(index)">
          <div class="align-end box-link-image" height="100%">
            <VImg aspect-ratio="1" class="box-image" cover :src="employee.Image?.url" />
            <div class="box-link-content text-white content-wrapper">
              <TransitionGroup name="fade">
                <div key="nameTitle">
                  <h4 class="box-link-title text-white">
                    {{ `${employee.Name}` }}
                  </h4>
                  <p class="box-link-description text-color-dark" :class="employee?.RegionField ? '' : 'pb-2'">
                    {{ employee.JobTitle }}
                  </p>
                  <p v-if="employee?.RegionField" class="box-link-description text-color-dark pb-2">{{ employee.RegionField }}</p>
                </div>
                <div key="contactDetails" class="position-static no-pointer" @click.stop>
                  <FadeIn>
                    <div v-show="(employee?.ContactDetails?.PhoneNumber || employee?.ContactDetails?.DirectNumber || employee?.ContactDetails?.MailAddress) && (hover === index || selected === index)">
                      <p v-if="employee?.ContactDetails?.PhoneNumber" class="-no-margin select-all clickable">{{ employee.ContactDetails?.PhoneNumber }}</p>
                      <p v-if="employee?.ContactDetails?.DirectNumber" class="-no-margin select-all clickable">{{ employee.ContactDetails?.DirectNumber }}</p>
                      <a v-if="employee?.ContactDetails?.MailAddress" class="select-all clickable" :href="`mailto:${employee.ContactDetails.MailAddress}`">
                        <p class="-no-margin">{{ employee.ContactDetails?.MailAddress }}</p>
                      </a>
                    </div>
                  </FadeIn>
                </div>
              </TransitionGroup>
            </div>
          </div>
        </VCard>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import FadeIn from '@/components/transitions/FadeIn.vue';

const hover = ref();
const selected = ref();

// As the strapi hook don't populate the data in employees, we need to pass the employees as a seperate prop
const props = defineProps({
  employees: {
    type: Array,
    required: true
  },
  employeesDepartment: {
    type: Array,
    required: true
  }
});

onMounted(() => {
  buildEmployeeList();
});

const employeesList = ref([]);

function buildEmployeeList() {
  props.employeesDepartment.forEach(employee => {
    employeesList.value.push(props.employees.find(obj => obj.id === employee.id.toString()));
  });
}

function toggleSelected(index) {
  selected.value = selected.value === index ? null : index;
}
</script>

<style lang="scss" scoped>
.v-card {
  position: relative;
  width: 100%;
  aspect-ratio: 20 / 27;
  border-radius: 10px;
  box-shadow: none;

  &.hovered span {
    color: $color-primary;
  }
}

.content-wrapper {
  padding: 1rem;

  @include is-screen-lg {
    padding: 2.5rem;
  }
}
.box-link-title {
  margin: 0 0 rem(2);
  transition: transform 1s ease-in-out;
}

.box-image {
  display: block;
  position: absolute;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: transform 0.5s ease-in-out;

  &.hovered {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100% + 100px);
  }

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100% + 100px);
    transform: translateY(0px);
    background: linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.8)), 100%;
    transition: background 0.5s ease-in-out;
  }
}

.box-link-card {
  position: relative;
  box-shadow: 0px 20px 40px 2px rgba(100, 100, 100, 0) !important;
  transition:
    transform 0.5s ease-in-out,
    box-shadow 0.5s ease-in-out;
  &.hovered {
    transform: translateY(-5px);
    box-shadow: 0px 20px 40px 2px rgba(100, 100, 100, 0.4) !important;

    .box-image {
      transform: scale(1.05);
      &::after {
        background-color: rgba($color-accent, 0.6);
        transform: translateY(-100px);
      }
    }
  }
}

.box-link-image {
  position: relative;
  display: block;
  height: 100%;
  transform: translateY(0);
  transition:
    box-shadow 0.5s ease-in-out,
    transform 0.5s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    background-size: cover;
    transform-origin: center;
    transition: transform 0.8s ease-in-out;
  }
}
.box-link-image.animate-up {
  transform: translateY(-5px);
  .productCatImg {
    &:after {
      transform: scale(1.2);
    }
  }
}

.select-all {
  user-select: all;
}
.no-pointer {
  pointer-events: none; // Disable clicks for the whole contact details section
}

.clickable {
  pointer-events: auto; // Re-enable clicks for specific interactive elements
}
.box-link-content {
  position: absolute;
  bottom: 0;
  padding: 20px;
  z-index: 10;
}

.box-link-title {
  margin-top: 0;
}

.box-link-description {
  margin-bottom: 0;
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-in;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  position: absolute;
}
</style>
