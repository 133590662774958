<template>
  <VDialog v-model="dialog" width="auto" min-width="500">
    <template #activator="{ props }">
      <Button color="secondary" variant="plain" class="mr-n5" size="small" v-bind="props">
        <VIcon icon="fa-regular fa-pen-to-square" />
      </Button>
    </template>
    <VCard v-scroll-lock="dialog" class="pa-10">
      <VCardTitle class="pl-10">
        <h4>{{ strapi.Title }}</h4>
      </VCardTitle>
      <VCardText>
        <VContainer>
          <VForm>
            <label class="mr-5 paragraph mb-1 opacity" for="editAddress">{{ strapi.AddressLabel }}</label>
            <TextField id="editAddress" v-model="deliveryAddress.address" class="mb-4" type="text" name="address" :disabled="loading" />
            <label class="mr-5 paragraph mb-1 opacity" for="editAddress">{{ strapi.Address2Label }}</label>
            <TextField id="editAddress" v-model="deliveryAddress.address2" class="mb-4" type="text" name="address" :disabled="loading" />
            <label class="mr-5 paragraph mb-1 opacity" for="editZip">{{ strapi.ZipLabel }}</label>
            <TextField id="editZip" v-model="deliveryAddress.zip" class="mb-4" type="text" name="zip" :disabled="loading" />
            <label class="mr-5 paragraph mb-1 opacity" for="editCity">{{ strapi.CityLabel }}</label>
            <TextField id="editCity" v-model="deliveryAddress.city" class="mb-4" type="text" name="city" :disabled="loading" />
            <template v-if="deliveryAddress.country?.requiresVat">
              <label class="mr-5 paragraph mb-1 opacity" for="deliveryAddressVAT">{{ strapi.VATLabel }}</label>
              <TextField id="deliveryAddressVAT" v-model="deliveryAddress.vat.vat_number" class="mb-4" name="customerDeliveryAddress.vat" :disabled="loading" />
            </template>
            <label class="mr-5 paragraph mb-1 opacity" for="editCountry">{{ strapi.CountryLabel }}</label>
            <SelectCountry id="deliveryAddressCountry" v-model="deliveryAddress.country" disabled name="customerDeliveryAddress.country_id" returnObject class="mb-4" />
            <template v-if="deliveryAddress.country?.hasStates">
              <label for="deliveryAddressState">{{ strapi.StateLabel }}</label>
              <SelectState id="deliveryAddressState" :key="customerDeliveryAddress.country" v-model="deliveryAddress.state" disabled :queryBuilder="qb => qb.where('freight_country_id', deliveryAddress.country.id).limit(100)" name="customerDeliveryAddress.state_id" returnObject class="mb-4" />
            </template>
          </VForm>
        </VContainer>
      </VCardText>
      <VCardActions class="px-10 d-flex justify-center">
        <Button variant="outlined" color="validation-red" :disabled="loading" @click="remove()">Delete</Button>
        <VSpacer />
        <Button variant="outlined" color="secondary" :disabled="loading" @click="cancel">Cancel</Button>
        <Button class="bg-secondary" color="white" variant="text" :disabled="loading" @click="save()">{{ strapi.SaveButton.Label }}</Button>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  name: 'EditDialog',
  props: {
    strapi: {
      type: Object,
      default: () => {}
    },
    customerDeliveryAddress: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      deliveryAddress: {
        id: this.customerDeliveryAddress.id,
        address: this.customerDeliveryAddress.address,
        address2: this.customerDeliveryAddress.address2,
        zip: this.customerDeliveryAddress.zip,
        city: this.customerDeliveryAddress.city,
        country_id: this.customerDeliveryAddress.country_id,
        country: this.customerDeliveryAddress.country,
        state: this.customerDeliveryAddress.state,
        state_id: this.customerDeliveryAddress.state_id,
        vat: {
          vat_number: this.customerDeliveryAddress.vat?.vat_number
        }
      }
    };
  },
  watch: {
    dialog(val) {
      this.$emit('onUpdateDialog', val);
    }
  },
  methods: {
    async save() {
      this.loading = true;
      const response = await this.put('/account/delivery-address/', { customerDeliveryAddress: this.deliveryAddress });
      if (!response) {
        this.loading = false;
        return;
      }
      this.reloadWithTimeout();
    },
    async remove() {
      this.loading = true;
      const response = await this.delete('/account/delivery-address/', { customerDeliveryAddress: this.deliveryAddress });
      if (!response) {
        this.loading = false;
        return;
      }
      this.reloadWithTimeout();
    },
    cancel() {
      this.isEditing = false;
      this.dialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: -78px;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  overflow-y: auto;
  display: block;
  z-index: 15;
  transition: background-color 0.2s ease-in-out;
  &.-visible {
    background-color: rgba(0, 0, 0, 0.25);
  }
}
.container-wrapper {
  opacity: 0;
  position: relative;
  width: 100%;
  z-index: 15;
  transform: translateY(100%);
  transition:
    opacity 0.4s ease-in-out,
    transform 0.4s ease-in-out 0.2s;
  &.-visible {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
