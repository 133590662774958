<template>
  <Card class="account-card" data-menu-bg="true">
    <template #title>
      <div class="position-absolute top-0 w-100">
        <div class="container d-flex align-center justify-end">
          <Button variant="plain" :ripple="false" href="/logout/">
            {{ strapi.SignOutButton.Label }}
            <VIcon :icon="strapi.SignOutButton.Icon" size="small" color="secondary" class="ml-2" />
          </Button>
        </div>
      </div>
      <div class="container d-flex pt-0 pt-lg-10 pb-10 pb-lg-20 bg-white align-center">
        <div class="bg-primary w-55px h-55px d-flex rounded-circle justify-center align-center mr-4">
          <VIcon icon="fak fa-user" size="x-large" />
        </div>
        <h1 class="text-secondary -no-margin">{{ strapi.Title }}</h1>
      </div>
      <div class="container account-tabs-container d-flex align-center justify-space-between pb-4">
        <VTabs v-model="tab" :show-arrows="true">
          <VTab v-for="(result, index) in strapi.AccountTabs" :key="index" :value="result.Text" sliderColor="primary" variant="plain" class="px-0 bold" :ripple="false" hideSlider>
            {{ result.Text }}
          </VTab>
        </VTabs>
      </div>
    </template>
    <div class="wrapper bg-grey-background py-10 py-lg-20">
      <div class="container">
        <VWindow v-model="tab" :touch="false">
          <VWindowItem v-for="(result, index) in strapi.AccountTabs" :key="index" :value="result.Text">
            <div v-if="result.Text === 'Account Details'" class="grid">
              <div class="grid grid-item -col-span-12 -col-span-lg-6">
                <AccountInformation v-model="user" class="grid-item -col-span-12" />
                <Subscriptions v-model="user" class="grid-item -col-span-12" />
                <InvoiceAddress v-model="user.customer.customerInvoiceAddress" :customer="user.customer" class="grid-item -col-span-12" />
                <DeliveryAddresses v-model="user.customer.customerDeliveryAddresses" :strapiAddDeliveryAddress="strapi.AddDeliveryAddress" class="grid-item -col-span-12" />
              </div>
              <div class="grid grid-item -col-span-12 -col-span-lg-6">
                <CompanyInformation v-model="user.customer" class="grid-item -col-span-12" />
                <Users v-model="user.customer.users" class="grid-item -col-span-12" />
                <FreightCompanyAccountNumbers v-model="user.customer.freightCompanyAccountNumbers" class="grid-item -col-span-12" />
              </div>
            </div>
            <OrderHistory v-if="result.Text === 'Order History'" :strapi="strapi.OrderHistory" />
            <SupportRequest v-if="result.Text === 'Support Requests'" :strapi="strapi.SupportRequests" />
            <RMARequest v-if="result.Text === 'RMA Requests'" :strapi="{ ...strapi.RMARequest, CustomerStatus: strapi.RmaCustomerStatuses }" />
          </VWindowItem>
        </VWindow>
      </div>
    </div>
  </Card>
</template>

<script>
import Subscriptions from '@Views/account/components/Subscriptions.vue';
import DeliveryAddresses from '@Views/account/components/DeliveryAddresses/DeliveryAddresses.vue';
import AccountInformation from '@Views/account/components/AccountInformation/AccountInformation.vue';
import CompanyInformation from '@Views/account/components/CompanyInformation/CompanyInformation.vue';
import Users from '@Views/account/components/Users/Users.vue';
import FreightCompanyAccountNumbers from '@Views/account/components/FreightCompanyAccountNumbers/FreightCompanyAccountNumbers.vue';
import OrderHistory from '@Views/account/components/OrderHistory/OrderHistory.vue';
import SupportRequest from '@Views/account/components/SupportRequests.vue';
import RMARequest from '@Views/account/components/RMARequest.vue';
import InvoiceAddress from '@Views/account/components/InvoiceAddress/InvoiceAddress.vue';

export default {
  name: 'AccountIndex',
  components: { Subscriptions, InvoiceAddress, AccountInformation, CompanyInformation, Users, FreightCompanyAccountNumbers, OrderHistory, SupportRequest, RMARequest, DeliveryAddresses },
  data() {
    return {
      ...this.$controller.data,
      loading: false,
      tabs: [],
      tab: 'account details',
      previousTab: undefined
    };
  },
  watch: {
    tab(newVal, oldVal) {
      this.scrollToTop();
      this.previousTab = oldVal;
      this.updateURLParameter('tab', newVal);
    }
  },
  created() {
    const type = this.getURLParameter('tab');
    if (type) {
      this.tab = type;
    }
  }
};
</script>

<style lang="scss" scoped>
.account-card {
  z-index: unset !important;
  overflow: unset !important;
  padding-top: $menu-height-desktop;
  margin-top: $menu-height-desktop;
}

:deep(.v-tabs) {
  .v-btn {
    font-size: initial;
    position: relative;
    transition: opacity 0.3s;

    &:not(:first-of-type) {
      margin-left: 40px !important;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 2px;
      background-color: $color-primary;
      transform: translateX(-50%);
      transition: width 0.3s;
    }

    &:hover::before,
    &.v-tab--selected::before {
      width: 100%;
    }
  }

  .v-tab--selected {
    opacity: 1;
  }
}

.account-tabs-container {
  position: sticky;
  top: 0;
  background-color: $color-white;
  opacity: 0.9;
  z-index: 3;
}

.v-card-text {
  .v-window {
    max-width: 1440px;
    margin: auto;
  }
}

.account-card {
  z-index: unset !important;
  overflow: unset !important;
}
</style>
