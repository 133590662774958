import FingerprintJS from '@fingerprintjs/fingerprintjs';

export default {
  mounted() {
    this.trackUserSession();
    this.updateActivity();
  },
  methods: {
    async trackUserSession() {
      try {
        const lastActivity = localStorage.getItem('lastActivity');
        const now = new Date();

        // Check if 30 minutes have passed since the last activity
        if (lastActivity && now - new Date(lastActivity) < 30 * 60 * 1000) {
          return;
        }

        const fp = await FingerprintJS.load();
        const result = await fp.get();

        const response = await this.post('/store-session/', {
          fingerprint: result.visitorId,
          referral_page: document.referrer,
          destination_page: document.location.pathname,
          destination_params: document.location.search
        });
        if (!response) return;

        localStorage.setItem('lastActivity', now.toISOString());
      } catch (error) {
        console.error('Error tracking session:', error);
      }
    },
    updateActivity() {
      const now = new Date().toISOString();
      localStorage.setItem('lastActivity', now);
    }
  }
};
