<template>
  <VExpansionPanelText>
    <Form :disabled="loading" :isEditing="isEditing" grid :columns="isEditing ? '1fr' : '1fr 1fr'" @submit.prevent="save">
      <label for="freightCompanyAccountNumberFreightCompanyName">{{ strapi.FreightCompanyLabel }}</label>
      <SelectFreightCompany v-if="isEditing" id="freightCompanyAccountNumberFreightCompanyName" :model-value="freightCompanyAccountNumber.freightCompany" name="freightCompanyAccountNumber.freight_company_id" disabled />
      <span v-else>{{ freightCompanyAccountNumber.freightCompany.name }}</span>
      <label for="freightCompanyAccountNumberServiceCode">{{ strapi.ServiceCodeLabel }}</label>
      <SelectFreightCompanyServiceCode v-if="isEditing" id="freightCompanyAccountNumberServiceCode" v-model="freightCompanyAccountNumber.freight_company_service_code_id" name="freightCompanyAccountNumber.freight_company_service_code_id" :queryBuilder="qb => qb.where('freight_company_id', freightCompanyAccountNumber.freight_company_id)" />
      <span v-else>{{ freightCompanyAccountNumber.freightCompanyServiceCode?.code }}</span>
      <label for="freightCompanyAccountNumberAccountNumber">{{ strapi.AccountNumberLabel }}</label>
      <TextField v-if="isEditing" id="freightCompanyAccountNumberAccountNumber" v-model="freightCompanyAccountNumber.account_number" name="freightCompanyAccountNumber.account_number" />
      <span v-else>{{ freightCompanyAccountNumber.account_number }}</span>
      <label for="freightCompanyAccountNumberDeliveryAddress">Delivery Address</label>
      <Select v-if="isEditing" id="freightCompanyAccountNumberCustomerDeliveryAddress" v-model="freightCompanyAccountNumber.customer_delivery_address_id" :items="customerDeliveryAddresses" eager item-title="fullAddress" clearable />
      <span v-else>{{ freightCompanyAccountNumber.customerDeliveryAddress?.fullAddress }}</span>
      <template #actions>
        <VDivider :class="[{ 'my-5': isMobile }, { 'my-10': isNotMobile }]" />
        <footer class="d-flex" :class="{ 'flex-column': isMobile }">
          <template v-if="isEditing">
            <Button :class="[{ 'mb-5': isMobile }, { 'mr-5': isNotMobile }]" color="secondary" variant="outlined" :disabled="loading" :block="isMobile" :loading="loading" type="submit">{{ strapi.SaveButtonLabel }}</Button>
            <Button color="secondary" variant="outlined" :disabled="loading" :block="isMobile" @click="cancel">{{ strapi.CancelButtonLabel }}</Button>
          </template>
          <template v-else>
            <Button :class="[{ 'mb-5': isMobile }, { 'mr-5': isNotMobile }]" color="secondary" variant="outlined" :block="isMobile" :disabled="loading" :prependIcon="strapi.EditButtonIcon" @click="edit">
              {{ strapi.EditButtonLabel }}
            </Button>
            <Button color="secondary" variant="outlined" :disabled="loading" :loading="loading" :block="isMobile" @click="_delete">{{ strapi.DeleteButtonLabel }}</Button>
          </template>
        </footer>
      </template>
    </Form>
  </VExpansionPanelText>
</template>

<script>
import singleSectionEditingHandling from '@Mixins/account/singleSectionEditingHandling';

export default {
  name: 'FreightCompanyAccountNumber',
  mixins: [singleSectionEditingHandling],
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      freightCompanyAccountNumber: { ...this.modelValue },
      customerDeliveryAddresses: [],
      loading: false
    };
  },
  async mounted() {
    await this.getDeliveryAddresses();
  },
  methods: {
    async getDeliveryAddresses() {
      const response = await this.get('freight-company-account-number/get-customer-delivery-addresses', { freightCompanyId: this.freightCompanyAccountNumber.freight_company_id });

      if (!response) {
        return;
      }

      this.customerDeliveryAddresses = response.customerDeliveryAddresses;
    },
    cancel() {
      this.isEditing = false;
      this.freightCompanyAccountNumber = { ...this.modelValue };
    },
    edit() {
      this.isEditing = true;
    },
    async save() {
      this.loading = true;
      const response = await this.put('freight-company-account-number/', { freightCompanyAccountNumber: { ...this.freightCompanyAccountNumber } });
      if (!response) {
        this.loading = false;
        return;
      }
      this.reloadWithTimeout();
    },
    async _delete() {
      this.loading = true;
      const response = await this.delete('freight-company-account-number/', { freightCompanyAccountNumber: { ...this.freightCompanyAccountNumber } });
      if (!response) {
        this.loading = false;
        return;
      }
      this.reloadWithTimeout();
    }
  }
};
</script>

<style lang="scss" scoped></style>
