<template>
  <div class="box-links container pb-10">
    <div class="grid card-container">
      <div v-for="(boxLink, index) in strapi.Cards" :key="index" class="grid-item -col-span-12 -col-span-md-4">
        <VCard :href="boxLink.Href" class="box-link-card" :class="{ hover: hover === index }" @mouseenter="hover = index" @mouseleave="hover = null">
          <div class="align-end box-link-image" height="100%">
            <VImg aspect-ratio="1" class="box-image" cover :style="`background-image: url('${boxLink.Image.url}');`" />
            <div class="box-link-content text-white content-wrapper">
              <h3 class="box-link-title text-white">
                <span class="box-link-description">{{ boxLink.TitleNumber }}</span>
                {{ boxLink.Title }}
              </h3>
              <p class="box-link-description paragraph-large">{{ boxLink.Description }}</p>
            </div>
          </div>
        </VCard>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BoxLinks',
  props: {
    strapi: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      hover: null,
      animateUp: null
    };
  }
};
</script>

<style lang="scss" scoped>
.v-card {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 10px;
  box-shadow: none;

  @include is-screen-md {
    aspect-ratio: 20 / 27;
  }

  &:hover span {
    color: $color-primary;
  }
}

.content-wrapper {
  padding: 1rem;

  @include is-screen-lg {
    padding: 2.5rem;
  }
}
.box-link-title {
  margin: 0 0 rem(8);

  @include is-screen-md() {
    margin: 0 0 rem(8);
  }
}

.box-image {
  display: block;
  position: absolute;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: transform 0.5s ease-in-out;

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100% + 100px);
    transform: translateY(0px);
    background: linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.8)), 100%;
    transition: transform 0.5s ease-in-out;
  }
}

.box-link-card {
  position: relative;
  box-shadow: 0px 20px 40px 2px rgba(100, 100, 100, 0) !important;
  transition:
    transform 0.5s ease-in-out,
    box-shadow 0.5s ease-in-out;
}

.box-link-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 20px 40px 2px rgba(100, 100, 100, 0.4) !important;

  .box-image {
    transform: scale(1.05);
    &::after {
      transform: translateY(-100px);
    }
  }
}

.box-link-image {
  position: relative;
  display: block;
  height: 100%;
  transform: translateY(0);
  transition:
    box-shadow 0.5s ease-in-out,
    transform 0.5s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    background-size: cover;
    transform-origin: center;
    transition: transform 0.8s ease-in-out;
  }
}
.box-link-image.animate-up {
  transform: translateY(-5px);
  .productCatImg {
    &:after {
      transform: scale(1.2);
    }
  }
}

.box-link-content {
  position: absolute;
  bottom: 0;
  padding: 20px;
}

.box-link-title {
  margin-top: 0;
}

.box-link-description {
  margin-bottom: 20px;
}
</style>
