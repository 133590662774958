<template>
  <div v-scroll-lock="dialog">
    <VDialog v-model="dialog" width="auto">
      <template #activator="{ props }">
        <Button class="mt-10" v-bind="props" color="secondary" variant="outlined" :disabled="editingIsDisabled" :prependIcon="strapi.AddButtonIcon">
          {{ strapi.AddButtonLabel }}
        </Button>
      </template>
      <Card class="pa-12">
        <h4>{{ strapi.DialogTitle }}</h4>
        <Form :disabled="loading" grid class="mt-10" @submit.prevent="save">
          <label for="addFreightCompanyAccountNumberFreightCompany">{{ strapi.FreightCompanyLabel }}</label>
          <SelectFreightCompany id="addFreightCompanyAccountNumberFreightCompany" v-model="freightCompanyAccountNumber.freight_company_id" name="freightCompanyAccountNumber.freight_company_id" />
          <label for="addFreightCompanyAccountNumberServiceCode">{{ strapi.DefaultServiceCodeLabel }}</label>
          <SelectFreightCompanyServiceCode id="addFreightCompanyAccountNumberServiceCode" :key="`service-code-${freightCompanyAccountNumber.freight_company_id}`" v-model="freightCompanyAccountNumber.freight_company_service_code_id" selectFirstItem :disabled="$utils.isBlank(freightCompanyAccountNumber.freight_company_id)" name="freightCompanyAccountNumber.freight_company_service_code_id" :queryBuilder="qb => qb.where('freight_company_id', freightCompanyAccountNumber.freight_company_id)" />
          <label for="addFreightCompanyAccountNumberAccountNumber">{{ strapi.AccountNumberLabel }}</label>
          <TextField id="addFreightCompanyAccountNumberAccountNumber" v-model="freightCompanyAccountNumber.account_number" name="freightCompanyAccountNumber.account_number" />
          <label for="freightCompanyAccountNumberDeliveryAddress">Delivery Address</label>
          <Select v-if="isEditing" id="freightCompanyAccountNumberCustomerDeliveryAddress" :key="`delivery-address-${freightCompanyAccountNumber.freight_company_id}`" v-model="freightCompanyAccountNumber.customer_delivery_address_id" :items="customerDeliveryAddresses" item-title="fullAddress" clearable />
          <template #actions>
            <footer class="mt-10">
              <Button class="bg-secondary" variant="text" color="white" :loading="loading" :disabled="loading" type="submit" :prepend-icon="strapi.AddButtonIcon">
                {{ strapi.AddButtonLabel }}
              </Button>
              <Button variant="outlined" class="ml-5" color="secondary" :disabled="loading" :prepend-icon="strapi.CancelButtonIcon" @click="cancel">
                {{ strapi.CancelButtonLabel }}
              </Button>
            </footer>
          </template>
        </Form>
      </Card>
    </VDialog>
  </div>
</template>

<script>
import singleSectionEditingHandling from '@Mixins/account/singleSectionEditingHandling';

export default {
  name: 'AddFreightCompanyAccountNumber',
  mixins: [singleSectionEditingHandling],
  data() {
    return {
      loading: false,
      dialog: false,
      freightCompanyAccountNumber: {},
      customerDeliveryAddresses: []
    };
  },
  watch: {
    dialog(val) {
      this.freightCompanyAccountNumber = {};
      this.isEditing = val;
    },
    'freightCompanyAccountNumber.freight_company_id': {
      async handler() {
        this.freightCompanyAccountNumber.customer_delivery_address_id = null;
        await this.getDeliveryAddresses();
      }
    }
  },
  methods: {
    async getDeliveryAddresses() {
      if (!this.freightCompanyAccountNumber.freight_company_id) {
        this.customerDeliveryAddresses = [];
        return;
      }
      const response = await this.get('freight-company-account-number/get-customer-delivery-addresses', { freightCompanyId: this.freightCompanyAccountNumber.freight_company_id });
      if (!response) {
        return;
      }

      this.customerDeliveryAddresses = response.customerDeliveryAddresses;
    },
    async save() {
      this.loading = true;
      const response = await this.post('freight-company-account-number/', { freightCompanyAccountNumber: this.freightCompanyAccountNumber });
      if (!response) {
        this.loading = false;
        return;
      }
      this.reloadWithTimeout();
    },
    cancel() {
      this.isEditing = false;
      this.dialog = false;
    }
  }
};
</script>
