<template>
  <div class="ourFocus pb-10 container d-flex flex-column">
    <div class="grid">
      <div class="grid-item -full-span mb-1">
        <h6 class="gradient-heading fs-70 fs-sm-120 fs-md-180 fs-lg-230 fs-xl-260" :class="{ 'ml-0': isMobile }">{{ strapi.Title }}</h6>
      </div>
      <div class="grid-item -col-span-12 -col-span-lg-7">
        <p class="preamble">{{ strapi.Description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OurFocus',
  props: {
    strapi: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.preamble-heading {
  margin-bottom: 30px;

  @include is-screen-lg() {
    margin-bottom: 20px;
  }
}
.preamble-heading:before {
  content: '';
  display: block;
  width: 2px;
  height: 27px;
  background: $color-primary;
  margin-right: 24px;
}

.gradient-heading {
  line-height: 1;
  font-weight: 400;
  background: radial-gradient(circle, $color-secondary 0%, $color-primary 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
