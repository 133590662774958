<template>
  <Card shadowHover :class="[`content-item-${contentType.toLowerCase()}`]">
    <a v-if="title" :href="item.route.publicShow" aria-label="go to a detailed page with more information" class="d-flex flex-column h-100 align-baseline box-border" :class="{ 'justify-start pa-6': isBrand || isVariant || isProduct, 'justify-space-end': isNews || isCustomerReference || isSupport || isService }">
      <div class="d-flex justify-center align-baseline w-100 h-100" :class="[isNews && isCustomerReference ? 'min-h-274px h-274px' : '', {}]">
        <VImg v-if="image" :src="image.url || image.src" :aspect-ratio="handleAspectRatio" class="w-100 h-100" :class="[{ 'max-w-368px': isProduct || isVariant }]" :cover="isCustomerReference || isSupport || isService || isNews" />
      </div>
      <div class="d-flex flex-column h-100 w-100 overflow-hidden" :class="[{ 'pa-6': isNews || isCustomerReference || isSupport | isService }, { 'pt-6': isBrand }]">
        <p v-if="isNews || isCareer" class="paragraph-small -no-margin text-grey mb-1">{{ $formatDateAsDayMonthYear(item.PublishDate || item.publishedAt) }}</p>
        <a v-if="isProduct || isVariant" :href="item.article?.category.route.publicShow" class="paragraph-small -no-margin underline-primary">
          {{ item.category?.name || item.article?.category?.name }}
        </a>
        <div class="" :class="[isProduct || isVariant || isNews ? 'min-h-44px max-h-44px min-h-lg-52px max-h-lg-52px mb-3' : 'min-h-22px max-h-22px min-h-lg-26px max-h-lg-26px mb-3']">
          <h4 class="h4 -no-margin" :class="isProduct || isVariant || isNews ? 'line-clamp-2' : 'line-clamp-1'">{{ title }}</h4>
        </div>
        <p v-if="isProduct || isVariant" class="paragraph-small -no-margin line-clamp-3 min-h-59px max-h-59px">
          <template v-for="(attribute, index) in item.attributes || item.article.attributes" :key="index">
            <span class="text-grey">{{ attribute.name }}:</span>
            <span class="mx-1" v-html="attribute.value" />
          </template>
        </p>
        <p v-else v-strip-html class="paragraph -no-margin line-clamp-3 min-h-65px max-h-65px text-grey-dark" :class="[{ 'paragraph-small': hasSquaredImage, 'line-clamp-lg-3 line-clamp-4 min-h-lg-60px max-h-lg-60px min-h-80px max-h-80px': isBrand, 'line-clamp-5 min-h-107px max-h-107px': isCustomerReference || isSupport || isService }]">{{ text }}</p>
        <p v-if="isNews" class="paragraph type-tag -no-margin mt-4" :class="{ 'text-accent technicalBg': newsType === 'Technical', 'text-primary generalBg': newsType === 'General', 'text-secondary productBg': newsType === 'Product' }">{{ newsType }}</p>
      </div>
    </a>
  </Card>
</template>

<script>
export default {
  name: 'ContentItem',
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    contentType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      item: this.modelValue
    };
  },
  computed: {
    isNews() {
      return this.contentType === 'News' || this.contentType === 'Blog Articles';
    },
    isCareer() {
      return this.contentType === 'Career';
    },
    isVariant() {
      return this.contentType === 'Highlighted Products';
    },
    isProduct() {
      return this.contentType === 'Popular Products';
    },
    isService() {
      return this.contentType === 'Services';
    },
    isCustomerReference() {
      return this.contentType === 'Customer References';
    },
    isBrand() {
      return this.contentType === 'Brands';
    },
    isSupport() {
      return this.contentType === 'Supports';
    },
    title() {
      switch (this.contentType) {
        case 'Highlighted Products':
          return this.item.article.name;
        case 'Popular Products':
          return this.item.name;
        default:
          return this.item.Title || this.item.Name;
      }
    },
    text() {
      switch (this.contentType) {
        case 'Customer References':
          return this.item.Introduction || '';
        case 'Brands':
          return this.item.Introduction || '';
        default:
          return this.item.Description || '';
      }
    },
    componentHight() {
      if (this.isVariant || this.isProduct) {
        return 'h-385px h-sm-560px';
      } else if (this.isBrand) {
        return 'h-460px';
      }
      return 'h-520px';
    },
    image() {
      switch (this.contentType) {
        case 'Highlighted Products':
          return this.item.article.primaryImage;
        case 'Popular Products':
          return this.item.primaryImage;
        default: {
          const targetWidth = 400;
          const targetHeight = 400;
          const targetResolution = targetWidth * targetHeight;
          let closestImage = this.item.Image; // Default to the original image

          if (this.item.Image && this.$isNotEmpty(this.item.Image.formats)) {
            let closestResolutionDifference = Infinity;

            this.$each(this.item.Image.formats, format => {
              const formatResolution = format.width * format.height;
              const resolutionDifference = Math.abs(targetResolution - formatResolution);

              if (resolutionDifference < closestResolutionDifference) {
                closestResolutionDifference = resolutionDifference;
                closestImage = format;
              }
            });
          }
          return closestImage;
        }
      }
    },
    newsType() {
      return this.item.Type || 'General';
    },
    hasSquaredImage() {
      return this.isProduct || this.isVariant || this.isBrand;
    },
    handleAspectRatio() {
      if (this.hasSquaredImage) {
        return 1;
      }
      return 2;
    }
  }
};
</script>

<style lang="scss" scoped>
.content-item-news {
  .type-tag {
    width: fit-content;
    border-radius: 4px;
    padding: 4px 8px;
  }
}
.technicalBg {
  background: rgba($color-accent, 0.1);
}
.generalBg {
  background: rgba($color-primary, 0.1);
}
.productBg {
  background: rgba($color-secondary, 0.1);
}
</style>
