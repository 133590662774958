<template>
  <Card class="bg-grey-background">
    <div class="container">
      <Form :disabled="loading" @submit.prevent="process">
        <div class="grid">
          <Card class="grid-item -col-span-12 -col-span-lg-6" :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
            <div class="d-flex justify-space-between">
              <header class="d-flex align-center">
                <VIcon :icon="strapiCheckoutPayment.Payment.SummaryIcon" size="24px" class="mr-3" />
                <h6 class="paragraph-large -no-margin font-weight-regular">{{ strapiCheckoutPayment.Payment.SummaryTitle }}</h6>
              </header>
              <Button variant="plain" plainNoAnimation :class="{ '-expanded': expandProducts }" @click="toggleExpandProducts">
                {{ expandProducts ? 'Hide products' : 'View products' }}
                <VIcon icon="fas fa-chevron-down" class="text-primary ml-2" size="15px" />
              </Button>
            </div>
            <VDivider :class="[{ 'mt-2 mb-5': isMobile }, { 'mt-4 mb-10': isNotMobile }]" />
            <div v-if="expandProducts" class="mb-8">
              <CartItem v-for="cartItem in cartItems" :key="cartItem.id" :cartItem="cartItem" :loading="loading" hideControls />
            </div>
            <div v-if="paymentSummary">
              <div class="d-flex justify-space-between">
                <p class="paragraph">{{ strapiCheckoutPayment.Payment.SubtotalLabel }}</p>
                <p class="paragraph">{{ $moneyFormat(paymentSummary.subtotal, paymentSummary.currency.code) }}</p>
              </div>
              <div v-if="paymentSummary.paymentFee" class="d-flex justify-space-between">
                <p class="paragraph">{{ paymentSummary.paymentFee.description }}</p>
                <p class="paragraph">{{ $moneyFormat(paymentSummary.paymentFee.cost, paymentSummary.currency.code) }}</p>
              </div>
              <div v-for="orderItem in paymentSummary.orderItems" :key="orderItem" class="d-flex justify-space-between">
                <div class="d-flex">
                  <p class="paragraph">{{ orderItem.label }}</p>
                  <VDialog v-if="$isNotUndefined(orderItem.dialog)" v-model="orderItem.dialog" max-width="500" width="auto">
                    <template #activator="{ props }">
                      <Button v-bind="props" iconButton icon="fas fa-circle-info" iconSize="small" color="info" aria-label="View order item description" class="hover-dark mt-n2" />
                    </template>
                    <Card class="pa-12">
                      <div v-markdown="orderItem.description" />
                      <template #actions>
                        <Button class="mt-8" variant="outlined" color="secondary" @click="orderItem.dialog = false">Ok</Button>
                      </template>
                    </Card>
                  </VDialog>
                </div>
                <p class="paragraph">{{ $moneyFormat(orderItem.totalPrice, paymentSummary.currency.code) }}</p>
              </div>
              <div v-if="paymentSummary.VAT" class="d-flex justify-space-between">
                <p class="paragraph">{{ strapiCheckoutPayment.Payment.VATLabel }} ({{ paymentSummary.VAT.percentage }}%)</p>
                <p class="paragraph">{{ $moneyFormat(paymentSummary.VAT.cost, paymentSummary.currency.code) }}</p>
              </div>
              <VDivider :thickness="2" color="black" class="divider" />
              <div class="d-flex justify-space-between mt-10 mb-10">
                <p class="paragraph-large">{{ strapiCheckoutPayment.Payment.TotalSumLabel }}</p>
                <p class="paragraph-large">{{ $moneyFormat(paymentSummary.total, paymentSummary.currency.code) }}</p>
              </div>
            </div>
            <VProgressCircular v-else :size="75" width="5" color="primary" indeterminate class="d-block ma-auto" />
          </Card>
          <Card class="grid-item -col-span-12 -col-span-lg-6" :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
            <header class="d-flex align-center">
              <VIcon icon="far fa-money-bill" size="24px" class="mr-3" />
              <h6 class="paragraph-large -no-margin font-weight-regular">{{ strapiCheckoutPayment.Payment.PaymentTitle }}</h6>
            </header>
            <VRow class="payment-methods justify-space-between mt-2">
              <VCol cols="12" class="payment-methods-wrapper">
                <Button v-for="paymentMethod in paymentMethods" :key="paymentMethod.id" class="payment-method min-w-160px w-30 h-100px mr-4 mt-4" :class="[paymentMethod.id === selectedPaymentMethod.id ? 'bg-primary' : 'bg-white']" @click="onSelectPaymentMethod(paymentMethod)">
                  <div class="d-flex align-center flex-column" :class="paymentMethod.id === selectedPaymentMethod.id ? 'text-white' : 'text-black'">
                    <VIcon :icon="paymentMethod.icon" size="32px" />
                    <span class="paragraph -no-margin font-weight-regular mt-2">{{ paymentMethod.name }}</span>
                  </div>
                </Button>
              </VCol>
            </VRow>
            <VRow>
              <!-- TODO: Move to strapi payment -->
              <VCol v-if="selectedPaymentMethod" cols="12">
                <StripeInformation v-if="selectedPaymentMethod.processor_name == 'Stripe'" :strapi="strapi.Stripe" />
                <PayPalInformation v-else-if="selectedPaymentMethod.processor_name == 'PayPal'" :strapi="strapi.PayPal" />
                <BankTransferInformation v-else-if="selectedPaymentMethod.processor_name == 'Bank Transfer'" :companyBankInformation="selectedPaymentMethod.companyBankInformation" />
                <InvoiceInformation v-else-if="selectedPaymentMethod.processor_name == 'Invoice'" :customer="customer" />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12" class="terms-conditons-and-submit-section d-flex justify-space-between mt-5 mt-sm-11">
                <div>
                  <Checkbox v-model="iAcceptTheTermsAndConditions" baseline name="checkout.terms_and_conditions" :hint="strapiCheckoutPayment.Payment.AgreementText" persistentHint>
                    <template #label>
                      <span class="paragraph-small -no-margin" v-html="strapiCheckoutPayment.Payment.AcceptTermsLabel" />
                    </template>
                  </Checkbox>
                </div>
                <Button iconButton class="mt-5 mt-sm-0 bg-accent" color="text-white" :disabled="disableConfirmOrder" :loading="loading" type="submit">
                  {{ strapiCheckoutPayment.Payment.ConfirmButton.Label }}
                  <VIcon :icon="strapiCheckoutPayment.Payment.ConfirmButton.Icon" />
                </Button>
              </VCol>
            </VRow>
          </Card>
        </div>
      </Form>
      <!-- TODO: Move to strapi payment -->
      <StripeDialog v-if="paymentProccesor == 'stripe'" ref="stripe" :strapi="strapi.Stripe" @onOpen="$emit('update:loading', false)" @onClose="paymentProccesor = null" />
      <PayPalDialog v-else-if="paymentProccesor == 'paypal'" ref="paypal" :strapi="strapi.PayPal" @onOpen="$emit('update:loading', false)" @onClose="paymentProccesor = null" />
    </div>
  </Card>
</template>

<script>
import CartItem from '@Components/CartItem.vue';
import StripeInformation from './StripeInformation.vue';
import PayPalInformation from './PayPalInformation.vue';
import BankTransferInformation from './BankTransferInformation.vue';
import InvoiceInformation from './InvoiceInformation.vue';
import StripeDialog from './StripeDialog.vue';
import PayPalDialog from './PayPalDialog.vue';

export default {
  name: 'Payment',
  components: { CartItem, StripeInformation, PayPalInformation, BankTransferInformation, InvoiceInformation, StripeDialog, PayPalDialog },
  props: {
    strapi: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    strapiCheckoutPayment: {
      type: Object,
      required: true
    }
  },
  emits: ['update:loading', 'nextStep', 'previousStep'],
  data() {
    return {
      isCreated: false,
      expandProducts: true,
      paymentMethods: [],
      cartItems: [],
      selectedPaymentMethod: null,
      iAcceptTheTermsAndConditions: false,
      paymentSummary: null,
      stripe: false,
      processorOptions: null,
      paymentProccesor: null
    };
  },
  computed: {
    disableConfirmOrder() {
      return !this.iAcceptTheTermsAndConditions || this.loading;
    }
  },
  watch: {
    async cartItems(cartItem) {
      await this.getPaymentSummary();
    },
    paymentMethods(value) {
      if (!this.selectedPaymentMethod) {
        this.selectedPaymentMethod = this.$first(value);
      }
    },
    async selectedPaymentMethod() {
      await this.getPaymentSummary();
    }
  },
  async created() {
    await this.getCartItems();
    await this.getPaymentMethods();
    this.isCreated = true;
    this.$mitt.on('cart:updated', () => {
      this.getCartItems();
    });
  },
  mounted() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'checkoutPaymentStepLoaded'
    });
  },
  methods: {
    onSelectPaymentMethod(paymentMethod) {
      this.selectedPaymentMethod = paymentMethod;
    },
    async getCartItems() {
      this.$emit('update:loading', true);
      const response = await this.get('/checkout/get-cart-items/');
      if (!response) return;
      this.$emit('update:loading', false);
      this.cartItems = response;
    },
    async getPaymentMethods() {
      this.$emit('update:loading', true);
      const response = await this.get('/checkout/get-payment-methods/');
      if (!response) return;
      this.$emit('update:loading', false);
      this.paymentMethods = response;
    },
    async getPaymentSummary() {
      this.paymentSummary = null;
      if (!this.selectedPaymentMethod) return;
      this.$emit('update:loading', true);
      const response = await this.get('/checkout/get-payment-summary/', { paymentMethodId: this.selectedPaymentMethod.id });
      if (this.$isEmpty(response)) return;
      this.$emit('update:loading', false);
      if (response.navigateToPreviousStep) {
        this.$emit('previousStep');
        return;
      }
      this.paymentSummary = response;
    },
    toggleExpandProducts() {
      this.expandProducts = !this.expandProducts;
    },
    async onNavigatedTo() {
      if (this.isCreated) {
        await this.getCartItems();
        await this.getPaymentMethods();
      }
    },
    async process() {
      this.$emit('update:loading', true);
      const startTime = Date.now();

      const response = await this.post('/checkout/process/');
      if (!response) {
        this.$emit('update:loading', false);
        return;
      }

      if (response.error) {
        this.$snackbar.error(response.error);
        this.$emit('update:loading', false);
        return;
      }

      // Function to ensure a minimum execution time
      const ensureMinTime = async minTime => {
        const elapsedTime = Date.now() - startTime;
        if (elapsedTime < minTime) {
          await new Promise(resolve => setTimeout(resolve, minTime - elapsedTime));
        }
      };

      switch (response.processor) {
        case 'stripe':
          this.paymentProccesor = 'stripe';
          this.$nextTick(async () => {
            await this.$refs.stripe.init(response);
          });
          break;
        case 'paypal':
          this.paymentProccesor = 'paypal';
          this.$nextTick(async () => {
            await this.$refs.paypal.init(response);
          });
          break;
        case 'bank_transfer':
          await ensureMinTime(1000);
          this.$redirect(`/checkout/?step=confirmation&orderNumber=${response.orderNumber}`);
          break;
        case 'invoice':
          await ensureMinTime(1000);
          this.$redirect(`/checkout/?step=confirmation&orderNumber=${response.orderNumber}`);
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-methods {
  .payment-method {
    outline: 1px solid lightgray;
  }
}
.payment-methods-wrapper {
  @include max-screen-sm() {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.terms-conditons-and-submit-section {
  @include max-screen-sm() {
    flex-direction: column;
  }
}
</style>
