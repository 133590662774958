<template>
  <Card :class="[{ 'pa-5': isMobile }, { 'pa-10': isNotMobile }]">
    <header class="d-flex flex-column">
      <div class="d-flex">
        <VIcon :icon="strapi.Icon" size="25px" class="mr-3" />
        <h6 class="paragraph-large font-weight-regular">{{ strapi.Title }}</h6>
      </div>
    </header>
    <ExpansionPanels>
      <ExpansionPanel v-for="freightCompanyAccountNumber in modelValue" :key="freightCompanyAccountNumber.id" :title="freightCompanyAccountNumber.freightCompany.name" class="pa-0" :icon="strapi.Icon" :disabled="editingIsDisabled">
        <FreightCompanyAccountNumber :model-value="freightCompanyAccountNumber" />
      </ExpansionPanel>
    </ExpansionPanels>
    <AddFreightCompanyAccountNumber />
  </Card>
</template>

<script>
import ExpansionPanels from '@Components/ExpansionPanels.vue';
import ExpansionPanel from '@Components/ExpansionPanel.vue';
import AddFreightCompanyAccountNumber from './AddFreightCompanyAccountNumber.vue';
import FreightCompanyAccountNumber from './FreightCompanyAccountNumber.vue';
import singleSectionEditingHandling from '@Mixins/account/singleSectionEditingHandling';

export default {
  name: 'FreightCompanyAccountNumbers',
  components: { AddFreightCompanyAccountNumber, FreightCompanyAccountNumber, ExpansionPanels, ExpansionPanel },
  mixins: [singleSectionEditingHandling],
  props: {
    modelValue: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  }
};
</script>
