<template>
  <Card class="customer-registration-card" data-menu-bg="true">
    <template #title>
      <div class="container d-flex pt-10 pb-20 bg-white">
        <div class="bg-primary w-55px h-55px d-flex rounded-circle justify-center align-center mr-4">
          <VIcon icon="fak fa-user" size="x-large" />
        </div>
        <h1 class="text-secondary -no-margin">{{ strapi.Title }}</h1>
      </div>
      <div class="container d-flex justify-end h-80px">
        <Button class="mt-6 h-20px" :ripple="false" variant="plain" :href="strapi.ContactLinkHref">
          {{ strapi.ContactLinkLabel }}
          <VIcon icon="fas fa-arrow-right" size="small" color="secondary" class="ml-2" />
        </Button>
      </div>
    </template>
    <div class="wrapper bg-grey-background pt-20">
      <div class="container">
        <Form ref="customerRegistrationForm" :disabled="loading" @submit.prevent="checkValidAndStartCaptcha">
          <CompanyInformation v-model="data.customer" :loading="loading" />
          <InvoiceAddress v-model="data.customerInvoiceAddress" :loading="loading" @onBusinessTypeSelect="data.business_type_id = $event" />
          <DeliveryAddress v-model="data.customerDeliveryAddress" :customer="data.customer" :customerInvoiceAddress="data.customerInvoiceAddress" :loading="loading" />
          <UserDetails v-model="data.user" :loading="loading" />
          <BusinessDescription v-model="data.customer" :customerPrimaryMarketOptions="customerPrimaryMarketOptions" :customerInvoiceAddress="data.customerInvoiceAddress" :loading="loading" />
          <!-- <SalesTaxExemption v-if="data.customerInvoiceAddress.country?.code === 'US'" ref="salesTaxExemption" v-model="data.salesTaxExemptionFiles" :loading="loading" /> -->
          <AgreementAndSecurity v-model:customer="data.customer" v-model:user="data.user" :loading="loading" />
          <Captcha ref="captcha" @verify="submit" @expired="resetCaptcha" />
          <div class="d-flex justify-center mt-6">
            <Button color="white" class="bg-success" :disabled="loading" :loading="loading" minWidth="150px" type="submit">
              {{ strapi.SubmitButtonLabel }}
            </Button>
          </div>
          <p class="mt-1 mb-0 text-grey text-right fs-11" :class="{ 'd-flex justify-center': isMobile }">
            <span class="mr-1">{{ strapi.ReCaptchaText }}</span>
            <a href="https://policies.google.com/privacy" aria-label="go to googles privacy policy" class="text-grey">{{ strapi.PrivacyPolicyLink }}</a>
            <span class="mr-1 ml-1">{{ strapi.AndText }}</span>
            <a href="https://policies.google.com/terms" aria-label="go to googles policy terms" class="text-grey">{{ strapi.TermsOfServiceLink }}</a>
            <span class="ml-1">{{ strapi.ApplyText }}</span>
          </p>
        </Form>
      </div>
    </div>
  </Card>
</template>

<script>
import CompanyInformation from './components/CompanyInformation.vue';
import InvoiceAddress from './components/InvoiceAddress.vue';
import DeliveryAddress from './components/DeliveryAddress.vue';
import UserDetails from './components/UserDetails.vue';
import BusinessDescription from './components/BusinessDescription.vue';
import AgreementAndSecurity from './components/AgreementAndSecurity.vue';
import Captcha from '@Components/Captcha.vue';

export default {
  name: 'AccountRegistrationNew',
  components: { CompanyInformation, InvoiceAddress, DeliveryAddress, UserDetails, BusinessDescription, AgreementAndSecurity, Captcha },
  data() {
    return {
      loading: false,
      valid: false,
      captcha: '',
      data: {
        customer: {},
        customerInvoiceAddress: {
          country: this.$country,
          state: this.$state
        },
        customerDeliveryAddress: {
          country: this.$country,
          state: this.$state
        },
        user: {
          has_newsletter: true
        }
      },
      ...this.$controller.data
    };
  },
  computed: {
    country() {
      return this.data.invoiceAddress.country;
    }
  },
  methods: {
    checkValidAndStartCaptcha() {
      this.loading = true;
      if (this.captcha === '') {
        this.$refs.captcha.execute();
      } else {
        this.submit(this.captcha);
      }
    },
    async submit(captcha) {
      this.captcha = captcha;
      const response = await this.post('', { captcha: this.captcha, ...this.data });

      if (!response) {
        this.loading = false;
        return false;
      }
    },
    resetCaptcha() {
      this.$refs.captcha.reset();
      this.captcha = '';
    }
  }
};
</script>

<style lang="scss" scoped>
.customer-registration-card {
  z-index: unset !important;
  overflow: unset !important;
  padding-top: $menu-height-desktop;
  margin-top: $menu-height-desktop;
}

.customer-registration-card .v-card {
  position: unset !important;
}

:deep(.grecaptcha-badge) {
  visibility: hidden;
}
</style>
