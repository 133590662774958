<template>
  <Card class="bg-transparent container contentList py-20 d-flex flex-column align-end">
    <Button v-if="strapi.TopLink" class="mb-4" variant="plain" :href="strapi.TopLink.Href">
      {{ strapi.TopLink.Label }}
      <VIcon icon="fa-regular fa-arrow-right" size="20px" color="secondary" class="ml-3" />
    </Button>
    <div class="grid">
      <ContentItem v-for="item in items" :key="item.id" class="grid-item -col-span-12 -col-span-sm-6 -col-span-md-4" :model-value="item" :contentType="strapi.ContentType" />
    </div>
    <Button v-if="showLoadMore" class="load-more bg-secondary" prependIcon="fa-regular fa-arrow-rotate-right" color="white" :loading="loading" :disabled="loading" @click="loadMore">Load more</Button>
  </Card>
</template>

<script>
import ContentItem from '@Components/ContentItem.vue';
export default {
  name: 'ContentList',
  components: { ContentItem },
  props: {
    strapi: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      items: [],
      limit: 9,
      currentOffset: 0,
      showLoadMore: false,
      firstFetch: true,
      totalItems: 0,
      itemsLoaded: 0
    };
  },
  async created() {
    this.items = await this.fetch(this.currentOffset);
  },
  methods: {
    async fetch(offset = 0) {
      this.loading = true;
      const type = this.strapi.ContentType.toLowerCase().replace(/\s/g, '-');
      const response = await this.get(`/api/content-type/${type}/`, { offset: offset, limit: this.limit });
      console.log(response);
      if (!response) return;
      // If total amount is returned on this endpoint set total items only on first fetch.
      if (this.firstFetch) {
        this.firstFetch = false;
        this.totalItems = response.total;
      }

      // Show or hide load more button
      this.itemsLoaded += response.items.length;
      if (this.itemsLoaded >= this.totalItems) {
        this.showLoadMore = false;
      } else {
        this.showLoadMore = true;
      }
      this.loading = false;
      return response.items;
    },
    async loadMore() {
      this.currentOffset += this.limit;
      const items = await this.fetch(this.currentOffset);
      this.items = [...this.items, ...items];
    }
  }
};
</script>

<style lang="scss" scoped>
.load-more {
  margin: 110px auto 115px;
}
</style>
